<template>
  <div>
    <div class="banner-additional-materials"
      @click="showAdditionalMaterialModal()">
      <h3>Materiały dodatkowe</h3>
    </div>
  </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'AdditionalMaterials',
  components: { BModal },
  methods: {
    showAdditionalMaterialModal() {
      this.$refs['additional-material-modal'].show()
    }
  }
}
</script>
<style lang="scss">
img, iframe {
  max-width: 100%;
  height: auto;
}

.banner-additional-materials{
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #20ad95;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.428rem;
  h3{
    color: #ffffff;
  }
}

</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
// prevent copying of text
/*.lesson-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}*/
/* Because of the SVG inliner, these don't work for icons */

@keyframes atom {
  from {
    transform: none;
  }
  to {
    transform: translateY(-10px);
  }
}

@keyframes electron-circle1 {
  from {
    transform: rotateY(70deg) rotateZ(20deg);
  }
  to {
    transform: rotateY(70deg) rotateZ(380deg);
  }
}

@keyframes electron1 {
  from {
    transform: rotateZ(-20deg) rotateY(-70deg);
  }
  to {
    transform: rotateZ(-380deg) rotateY(-70deg);
  }
}

@keyframes electron-circle2 {
  from {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
  }
  to {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg);
  }
}

@keyframes electron2 {
  from {
    transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
  }
  to {
    transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg);
  }
}

@keyframes electron-circle3 {
  from {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
  }
  to {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg);
  }
}

@keyframes electron3 {
  from {
    transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
  }
  to {
    transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg);
  }
}

</style>